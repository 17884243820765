.tokens {
  display: flex;
  flex-direction: column;

  &__row {
    display: grid;
    grid-template-columns: auto minmax(0, 3fr) minmax(0, 4fr);
    gap: var(--spacing-200);
    justify-content: start;
    align-items: center;

    padding: var(--spacing-300) var(--spacing-200);

    border-top: 1px solid var(--border-emphasis);

    &:first-of-type {
      border-top: none;
    }
  }
}

.token {
  margin-bottom: var(--spacing-100);
  padding-bottom: var(--spacing-100);

  border-bottom: 1px solid var(--border-default);

  &:last-child {
    margin-bottom: 0;

    border-bottom: none;
  }
}
