@import "@aidnas/design-system/styles.css";

html {
  --font-size-large: 20px;
  --font-size-regular: 16px;
  --font-size-small: 14px;
  --header-height: 42px;
  --max-content-width: 840px;

  background-color: var(--background-default);
}

body {
  color: var(--text-default);
}

/* stylelint-disable-next-line selector-class-pattern */
.ant-table-cell {
  align-content: flex-start;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

code {
  display: inline-flex;
  gap: var(--spacing-100);

  padding: 0 4px;
  padding: 0 var(--spacing-50);

  font-size: 90%;
  font-family: var(--font-mono);
  white-space: nowrap;
  text-align: left;

  border: none;
  border-radius: var(--radius-xs);

  background: var(--surface-muted);

  outline: var(--spacing-25) solid var(--surface-muted);
}

a {
  color: var(--text-interactive-accent);
  text-decoration: underline;

  &:hover {
    color: var(--text-interactive-accent-hover);
  }

  &:active {
    color: var(--text-interactive-accent-pressed);
  }
}
