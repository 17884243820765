.icons_block {
  display: flex;
  flex-direction: column;

  &__row {
    display: grid;
    grid-template-columns: auto minmax(0, 3fr) minmax(0, 4fr);
    gap: var(--spacing-200);
    align-items: center;

    padding: var(--spacing-300) var(--spacing-200);

    border-top: 1px solid var(--border-emphasis);

    &:first-of-type {
      border-top: none;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
  }

  &__code {
    white-space: nowrap;
  }

  &__description {
    overflow-wrap: break-word;
  }
}
