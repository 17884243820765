.inline_code {
  display: inline-flex;

  code {
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;

    user-select: all;
  }
}
